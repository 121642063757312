<template>
    <div>
        <Select v-model="city" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择市" multiple filterable @on-change="changeCity">
            <Option :value="item.value" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
        </Select>
        <Select v-model="region" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择区" :disabled="regionDisabled" multiple filterable @on-change="changeRegion">
            <Option :value="item.value" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
        </Select>
        <Select v-model="stree" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择街道/乡镇" :disabled="streeDisabled" multiple filterable @on-change="changeStree">
            <Option :value="item.value" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
        </Select>
        <Select v-model="project" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择社区" :disabled="projectDisabled" multiple filterable>
            <Option :value="item.value" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
        </Select>
    </div>
</template>

<script>
export default {
    props: {
        dataScopeList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            city: [],
            cityList: [],
            region: [],
            regionList: [],
            stree: [],
            streeList: [],
            project: [],
            projectList: [],
            regionDisabled: true,
            streeDisabled: true,
            projectDisabled: true,
        }
    },
    methods: {
        // 暴露子级code
        exportCode() {
            if (this.project.length > 0) {
                return this.project
            } else if (this.stree.length > 0) {
                return this.stree
            } else if (this.region.length > 0) {
                return this.region
            } else {
                return this.city
            }
        },
        // 城市选择
        changeCity(code) {
            if (code.length >= 2) {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.region = []
                this.stree = []
                this.project = []
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.regionDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region")
            } else {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.region = []
                this.stree = []
                this.project = []
            }
        },
        // 区选择
        changeRegion(code) {
            if (code.length >= 2) {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.streeDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
            } else {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
            }
        },
        // 街道选择
        changeStree(code) {
            if (code.length >= 2) {
                this.projectList = []
                this.project = []
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.projectDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "projectList", "project")
            } else {
                this.projectList = []
                this.project = []
            }
        },
        // 获取发布范围列表
        getPulishData(code, list, model) {
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                        }
                    })
                    if (res.dataList.length == "1") {
                        this[model] = [res.dataList[0].orgPath]
                    }
                }
            })
        },
    },
    created() {
        this.getPulishData("44", "cityList", "city")
        if (this.dataScopeList.length == 1) {
            let arr = this.dataScopeList[0].split("-")
            switch (arr.length) {
                case 2:
                    this.city = [arr[0] + "-" + arr[1]]
                    break
                case 3:
                    this.city = [arr[0] + "-" + arr[1]]
                    this.region = [arr[0] + "-" + arr[1] + "-" + arr[2]]
                    break
                case 4:
                    this.city = [arr[0] + "-" + arr[1]]
                    this.region = [arr[0] + "-" + arr[1] + "-" + arr[2]]
                    this.stree = [arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]]
                    break
                case 5:
                    this.city = [arr[0] + "-" + arr[1]]
                    this.region = [arr[0] + "-" + arr[1] + "-" + arr[2]]
                    this.stree = [arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]]
                    this.project = [arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3] + "-" + arr[4]]
                    break
            }
            this.city = Array.from(new Set(this.city))
            this.region = Array.from(new Set(this.region))
            this.stree = Array.from(new Set(this.stree))
            this.project = Array.from(new Set(this.project))
        } else if (this.dataScopeList.length > 1) {
            this.dataScopeList.map(item => {
                let arr = item.split("-")
                switch (arr.length) {
                    case 2:
                        this.city.push(item)
                        break
                    case 3:
                        this.city.push(arr[0] + "-" + arr[1])
                        this.region.push(item)
                        break
                    case 4:
                        this.city.push(arr[0] + "-" + arr[1])
                        this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                        this.stree.push(item)
                        break
                    case 5:
                        this.city.push(arr[0] + "-" + arr[1])
                        this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                        this.stree.push(arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3])
                        this.project.push(item)
                        break
                }
            })
            this.city = Array.from(new Set(this.city))
            this.region = Array.from(new Set(this.region))
            this.stree = Array.from(new Set(this.stree))
            this.project = Array.from(new Set(this.project))
        }
    },
}
</script>
    
<style scoped lang='less'>
</style>